import React from 'react';
import './Game.css';
import { EASY, MEDIUM, HARD } from '../../const/Modes'

class Game extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            maxPow: 0,
            items: [],
            resultHorizontal: [],
            resultVertical: [],
            currentTotalVertical: [],
            currentTotalHorizontal: [],
            isTrueVertical: [],
            isTrueHorizontal: [],
            isFinish: false,
            giveUp: false
        }
    }


    clickHandler = (e) => {
        let index = e.target.id;
        let {
            items,
            resultHorizontal,
            currentTotalVertical,
            currentTotalHorizontal,
            isTrueVertical,
            resultVertical,
            isTrueHorizontal,
            maxPow,
            isFinish
        } = this.state;
        if (!isFinish) {
            let column = index % maxPow;
            let row = Math.floor(index / maxPow);


            if (items[index].isActive) {
                items[index].isActive = 0;
                currentTotalVertical[column] -= Math.pow(2, items[index].rowValue);
                currentTotalHorizontal[row] -= Math.pow(2, items[index].colValue);
            } else {
                items[index].isActive = 1;
                currentTotalVertical[column] += Math.pow(2, items[index].rowValue);
                currentTotalHorizontal[row] += Math.pow(2, items[index].colValue);
            }

            if (resultHorizontal[column] === currentTotalVertical[column]) {
                isTrueVertical[column] = 1;
            } else {
                isTrueVertical[column] = 0
            }

            if (resultVertical[row] === currentTotalHorizontal[row]) {
                isTrueHorizontal[row] = 1;
            } else {
                isTrueHorizontal[row] = 0
            }
            this.isFinish();
            this.setState({
                items,
                resultHorizontal,
                currentTotalVertical,
                currentTotalHorizontal,
                isTrueVertical,
                resultVertical,
                isTrueHorizontal,
            })
        }
    };

    randomBinary() {
        return Math.floor(Math.random() * 10) % 2
    }

    isFinish = () => {
        let { isTrueHorizontal, isTrueVertical, maxPow } = this.state;
        let isCompleteHorizontal = 0;
        let isCompleteVertical = 0;

        isTrueHorizontal.map(x => {
            isCompleteHorizontal += x;
        });

        isTrueVertical.map(x => {
            isCompleteVertical += x;
        });

        let isFinish = isCompleteVertical + isCompleteHorizontal === maxPow * 2 ? true : false;

        if (isFinish) {
            this.finishTime = Date.now();
        }

        this.setState({
            isFinish
        });
    };

    setItems = () => {
        let {
            items,
            maxPow,
            resultHorizontal,
            currentTotalVertical,
            currentTotalHorizontal,
            resultVertical
        } = this.state;

        switch (this.props.mode) {
            case 'easy':
                items = EASY;
                maxPow = 4;
                break;

            case 'medium':
                items = MEDIUM;
                maxPow = 6;
                break;

            case 'hard':
                items = HARD;
                maxPow = 8;
                break;
        }


        for (let i = 0; i < maxPow; i++) {
            resultVertical[i] = 0;
            resultHorizontal[i] = 0;
            currentTotalHorizontal[i] = 0;
            currentTotalVertical[i] = 0;
        }

        do {
            for (let i = 0; i < maxPow; i++) {
                resultVertical[i] = 0;
                resultHorizontal[i] = 0;
            }
            items.map((item, index) => {
                let randomBinary = this.randomBinary();
                item.binaryValue = randomBinary;
                let column = index % maxPow;
                let row = Math.floor(index / maxPow);
                if (randomBinary) {
                    resultVertical[column] += Math.pow(2, items[index].rowValue);
                    resultHorizontal[row] += Math.pow(2, items[index].colValue);
                }
            });


        } while (this.isItZero(resultHorizontal, resultVertical, maxPow));

        this.setState({
            items,
            resultVertical,
            resultHorizontal,
            maxPow,
            currentTotalVertical,
            currentTotalHorizontal
        });
    };


    giveUpHandler = () => {
        let { items } = this.state;

        items.map(item => {
            item.isActive = items.filter(obj => {
                return (obj.rowValue === item.colValue && obj.colValue === item.rowValue);
            })[0].binaryValue;
        });

        this.finishTime = this.startTime;

        this.setState({
            isFinish: true,
            items,
            giveUp: true
        })
    };

    continueHandler = () => {
        let { items } = this.state;

        items.map(item => {
            item.isActive = 0;
        });

        this.setState({
            maxPow: 0,
            items: [],
            resultHorizontal: [],
            resultVertical: [],
            currentTotalVertical: [],
            currentTotalHorizontal: [],
            isTrueVertical: [],
            isTrueHorizontal: [],
            isFinish: false,
            giveUp: false
        });

        this.finishTime = null;

        this.setItems();
        this.startGame();
    };

    startGame = () => {
        this.started = true;
        this.forceUpdate();
        localStorage.setItem('started', true);
        this.startTime = Date.now();
    };

    goBack = () => {
        let { items } = this.state;

        items.map(item => {
            item.isActive = 0;
        });
        this.props.screen();
    };


    isItZero(resultHorizontal, resultVertical, maxPow) {

        for (let i = 0; i < maxPow; i++) {
            if (resultHorizontal[i] === 0 || resultVertical[i] === 0) {
                return 1;
            }
        }

        return 0;
    };


    componentDidMount() {
        this.interval = setInterval(() => this.setState({ time: Date.now() }), 5);
        this.setItems();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }


    render() {
        this.started = this.started || false;
        const {
            items,
            resultHorizontal,
            isTrueVertical,
            resultVertical,
            isTrueHorizontal,
            maxPow,
            isFinish,
            giveUp
        } = this.state;
        const { mode } = this.props;
        let elapsed = this.finishTime ? ((this.finishTime - this.startTime) / 1000) : ((Date.now() - this.startTime) / 1000);
        let seconds = Math.floor(elapsed % 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) + "." + (elapsed % 1).toFixed(2).toString().split('.')[1];
        let minutes = Math.floor(elapsed / 60);
        if (localStorage.getItem('started') === 'true' && this.started === false) {
            this.startGame();
        }
        return (
            <div>
                {
                    this.started ?
                        (
                            <div>
                                {
                                    <div id="clock" style={{ textAlign: "left", width: "7rem", position: "absolute", top: "0", right: "0", marginTop: "1vh", color: "white", fontSize: "30px" }}>
                                        {minutes}:{seconds}
                                    </div>
                                }
                                <div style={{ color: 'white', margin: '10vh auto', textAlign: 'center', marginBottom: '10px' }}>
                                    {

                                        isFinish && !giveUp &&
                                        (
                                            <h1>You fixed the bug!!</h1>
                                        )
                                    }{
                                        giveUp && (
                                            <h1>You gave up!!!</h1>
                                        )
                                    }{
                                        !isFinish && !giveUp && (
                                            <h1>Now it's your job to fix the bug!!</h1>
                                        )
                                    }
                                </div>
                                <div className={"container " + mode + "Container"}>
                                    {
                                        items.map((item, index) => {
                                            if (index % maxPow === maxPow - 1) {
                                                return (
                                                    <div style={{ display: "flex" }}>
                                                        <div
                                                            className={"item " + mode + "Item " + (item.isActive ? "activeItem" : "inactiveItem")}
                                                            onClick={this.clickHandler} id={index} key={index}>
                                                            {item.isActive}
                                                        </div>
                                                        <div
                                                            className={"item " + mode + "Item " + (isTrueHorizontal[Math.floor(index / maxPow)] ? "resultItemTrue" : "resultItemFalse")}>
                                                            {resultVertical[Math.floor(index / maxPow)]}
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div
                                                        className={"item " + mode + "Item " + (item.isActive ? "activeItem" : "inactiveItem")}
                                                        onClick={this.clickHandler} id={index} key={index}>
                                                        {item.isActive}
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                    {
                                        resultHorizontal.map((resultHorizontal, index) => {
                                            return (
                                                <div
                                                    className={"item " + mode + "Item " + (isTrueVertical[index % maxPow] ? "resultItemTrue " : "resultItemFalse ")}
                                                    key={index}>
                                                    {resultHorizontal}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        ) : (
                            <div>
                                <iframe style={{ padding: '5%' }} width="560" height="315" src="https://www.youtube-nocookie.com/embed/ukEI8ud8WrI?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        )
                }
                <div className="gameButtonArea">
                    <div className="button gameButton" onClick={this.goBack}>
                        Go Back
                    </div>
                    {
                        isFinish && !giveUp &&
                        (
                            <div className="button gameButton" onClick={this.continueHandler}>
                                Continue
                            </div>
                        )
                    }
                    {
                        this.started && !isFinish && !giveUp &&
                        (
                            <div className="button gameButton" onClick={this.giveUpHandler}>
                                Give Up
                            </div>
                        )
                    }
                    {
                        !this.started &&
                        (
                            <div className="button gameButton" onClick={this.startGame}>
                                Start Game
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}


export default Game;